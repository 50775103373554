
import { Component, Vue } from 'vue-property-decorator'
import store from '@/store'
import { UserRole } from '@/enums'

@Component({})
export default class Navigation extends Vue {

    role = store.getters['identity/role']
    items = this.role == UserRole.Moderator ? [
        { title: 'Home', icon: 'mdi-home', path: '/mod'},
        { title: 'EMRA-box toevoegen', icon: 'mdi-home-plus', path: '/mod/add-emra'},
        { title: 'Data', icon: 'mdi-calendar-filter', path: '/mod/data' },
        { title: 'Berekeningen', icon: 'mdi-chart-timeline-variant', path: '/mod/mod-node'}
    ]
    : [
        { title: 'Home', icon: 'mdi-home', path: '/default' },
        { title: 'Data', icon: 'mdi-calendar-filter', path: '/default/data' }
    ]

}


import { Component, Vue } from 'vue-property-decorator'

import Navigation from '@/views/default/Navigation.vue'

import ComparisonMenu from '@/views/default/ComparisonMenu.vue'

@Component({
    components: {
        Navigation,
        ComparisonMenu
    }
})
export default class DefaultMain extends Vue {

    created(): void {
        this.$store.dispatch('default/init')
        this.$store.dispatch('addEmra/init')
    }
}
